


























































































































































































































































































































































































































































































































































import Vue from "vue";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  download_file,
  convert_seconds_to_minutes,
  parseStrToInt,
  update_assessments_scores
} from "@/utils/global";
import {
  AppAssessmentNames,
  SiteDirections
} from "@/store/modules/common/interfaces";
import {
  APP_ASSESSMENTS,
  APP_ASSESSMENT_RESULT,
  APP_ASSESSMENT_RESULT_PDF,
  SITE_DIRECTION
} from "@/store/modules/common/constants";
import { get_color_by_name } from "@/utils/colors";
import AppResourseNotFound from "@/components/candidate/app_assessments/AppResourseNotFound.vue";
import AssessmentResultLoading from "@/components/candidate/app_assessments/AssessmentResultLoading.vue";
import AnnotationAssessmentScoreCard from "@/components/recruiter/app_assessment/Annotation/AnnotationAssessmentScoreCard.vue";
import AssessmentViewDetails from "@/components/recruiter/app_assessment/AssessmentViewDetails.vue";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import { AppAnnotationAssessmentListing } from "@/store/modules/recruiter/interfaces";
import { APP_ANNOTATION_ASSESSMENT_LISTING } from "@/store/modules/recruiter/constants";
import AiEvaluationCard from "@/components/recruiter/app_assessment/AiEvaluationCard.vue";
import GoBackHeaderPercentage from "@/components/recruiter/app_assessment/GoBackHeaderPercentage.vue";

export default Vue.extend({
  name: "AppAnnotationReportDetails",
  components: {
    AppResourseNotFound,
    AssessmentResultLoading,
    AnnotationAssessmentScoreCard,
    AssessmentViewDetails,
    DataNotFound,
    AiEvaluationCard,
    GoBackHeaderPercentage
  },
  data() {
    return {
      loading: true,
      data_error: "",
      data: null as AppAssessments.AnnotationAssessmentResults | null,
      is_assessment_details: false,
      download_report_loading: false,
      version: "v1" as string
    };
  },
  beforeRouteEnter(to, from, next) {
    const version = to.params.version;
    if (version === "v1" || version === "v2" || version === "v3") {
      next();
    } else {
      next({ name: "annotation-assessment-not-found" });
    }
  },
  beforeRouteUpdate(to, from, next) {
    const version = to.params.version;
    if (version === "v1" || version === "v2" || version === "v3") {
      next();
    } else {
      next({ name: "annotation-assessment-not-found" });
    }
  },
  created() {
    this.version = this.$route.params.version || "v1";
  },
  computed: {
    AppAssessments() {
      return AppAssessments;
    },
    ...mapGetters("recruiter", {
      app_annotation_assessment_listing: APP_ANNOTATION_ASSESSMENT_LISTING
    }),
    ...mapGetters("common", {
      app_assessments: APP_ASSESSMENTS,
      get_site_direction: SITE_DIRECTION
    }),
    SiteDirections() {
      return SiteDirections;
    },
    CurrentAnnotationAssessment(): AppAnnotationAssessmentListing {
      return this.app_annotation_assessment_listing(this.version);
    },
    get_percentage(): number {
      return Object.values(AppAssessments.AnnotationAssessmentRefs).reduce(
        (acc, key) => {
          if (this.data && this.data.result) {
            const part = this.data.result[key];
            if (part) {
              acc += part.score;
            }
            return acc;
          }
          return 0;
        },
        0
      );
    }
  },
  async mounted() {
    await this.init();
  },
  methods: {
    get_color_by_name,
    convert_seconds_to_minutes,
    update_assessments_scores,
    ...mapActions("common", {
      fetch_assessment_result: APP_ASSESSMENT_RESULT,
      fetch_assessment_pdf: APP_ASSESSMENT_RESULT_PDF
    }),
    ...mapMutations({
      root_error: ROOT_ERROR,
      root_notification: ROOT_NOTIFICATION
    }),
    async init() {
      this.loading = true;
      this.data_error = "";
      const assessment_result_id = parseStrToInt(this.$route.params.id);
      if (!assessment_result_id) {
        this.data_error = `${this.$t("assessments.not-found")}`;
        this.loading = false;
        return;
      }
      const user_id = parseStrToInt(this.$route.params.user_id);
      if (!user_id) {
        this.data_error = this.$t("assessments.user-not-found").toString();
        this.loading = false;
        return;
      }
      let assessment_to_find = "";
      switch (this.version) {
        case "v1":
          assessment_to_find = AppAssessmentNames.annotation_v1;
          break;
        case "v2":
          assessment_to_find = AppAssessmentNames.annotation_v2;
          break;
        case "v3":
          assessment_to_find = AppAssessmentNames.annotation_v3;
          break;
        default:
          throw new Error("Unsupported version");
      }
      const assessment = this.app_assessments.find(
        (val: AppAssessments.Assessment) =>
          val.assessment_type === assessment_to_find
      );
      // If assessment not found in common store, then show error
      if (!assessment) {
        this.data_error = `${this.$t("assessments.not-found")}`;
        this.loading = false;
        return;
      }
      // Fetching assessment result from server
      const res = await this.fetch_assessment_result({
        assessment_id: assessment.id,
        user_id,
        assessment_result_id
      });
      if (!res) {
        this.data_error = `${this.$t("assessments.not-found")}`;
        this.loading = false;
        return;
      }
      this.data = res; // Setting data
      if (
        this.data &&
        (this.data.status === AppAssessments.AppAssessmentStatus.FAILED ||
          this.data.status === AppAssessments.AppAssessmentStatus.IN_PROGRESS)
      ) {
        this.data_error = `${this.$t("assessments.assessment-failed")}`;
        this.loading = false;
        return;
      }
      this.loading = false;
    },
    /**
     * Get questions count by filter type (short or long)
     * @param filter {AppAssessments.QuestionFilter} - filter type
     */
    get_questions_count() {
      if (!this.data) return "";
      try {
        const details = this.data.result;
        if (!details) return "";
        return (
          Object.values(details).length - 1 + ` ${this.$t("shared.question")}`
        );
      } catch (e) {
        return "";
      }
    },
    // Function to download report
    async download_report() {
      if (!this.data) return;
      this.download_report_loading = true;
      const pdf = this.data.urls?.report;
      if (!pdf) {
        // Call backend API to get report
        const res = await this.fetch_assessment_pdf({
          assessment_id: this.data.assessment.id,
          user_id: this.data.user_id
        });
        if (!res) {
          this.root_error(
            `${this.$t("assessments.failed-to-get-pdf").toString()}`
          );
          this.download_report_loading = false;
          return;
        }
        await download_file(res);
      } else await download_file(pdf);
      this.download_report_loading = false;
    },
    open_details_dialog() {
      if (this.data && this.data.urls) {
        this.is_assessment_details = true;
      } else {
        this.root_notification(
          `${this.$t("assessments.recording-processing").toString()}`
        );
      }
    },
    get_translation(key: string) {
      return this.$t(`assessments.annotation.${key}`);
    },
    get_name(data: AppAssessments.AnnotationAssessmentResults) {
      if (data.user) return data.user?.first_name + " " + data.user?.last_name;
    }
  }
});
